import { observer } from 'mobx-react-lite';
import moment from 'moment';
import React, { useEffect, useRef, useState, useContext } from 'react';
import { IoMdRefresh } from 'react-icons/io';
import './style.css';
import { DialogContext } from '../../store/context/DialogContext';
import { useParams } from 'react-router-dom';
import { doGET } from '../../util/httpUtil';
import { ENDPOINTS } from '../../common/utils/Constants';
import ImageComponent from '../../components/Image/ImageComponent';
import AudioPlayer from '../../components/AudioPlayer/AudioPlayer';
import logo from '../../assets/images/wishtoklogo.png';

const getMessageTime = (sentAt) => {
    const itemDate = moment(sentAt * 1000);
    const today = moment();
    const yesterday = moment().subtract(1, 'days');

    if (itemDate.isSame(today, 'day')) return "TODAY";
    if (itemDate.isSame(yesterday, 'day')) return "YESTERDAY";
    if (today.diff(itemDate, 'days') <= 7) return itemDate.format("dddd").toUpperCase();
    return itemDate.format("DD/MM/YYYY");
};

const groupMessagesByDay = (messages) => {
    const grouped = {};
    messages?.forEach(message => {
        const day = getMessageTime(message?.at);
        if (!grouped[day]) grouped[day] = [];
        grouped[day].push(message);
    });
    return grouped;
};

const MessageList = ({ messages,customer, extra }) => {
    const groupedMessages = groupMessagesByDay(messages);
    const messageListRef = useRef(null);

    useEffect(() => {
        if (messageListRef.current) {
            messageListRef.current.scrollTop = messageListRef.current.scrollHeight;
        }
    }, [messages]);

    return (
        <div className='wishtok_whatsapp_message-list' ref={messageListRef}>
            {Object.keys(groupedMessages).reverse().map(day => (
                <div key={day}>
                    <div className='d-flex justify-content-center align-items-center my-3'>
                        <div className="date d-flex text-center justify-content-center align-items-center">{day}</div>
                    </div>
                    {groupedMessages[day]
                        .slice().reverse()
                        .map(message => (
                            <div className={`d-flex justify-content-${message.by === customer ? "end" :message.by ==="WISHTOK" ?"center" : "start"}`} key={message._id}>
                                <div className={`wishtok_whatsapp_message-item wishtok_whatsapp_message-${message.by === customer ? "self" : "other"}`}>
                                    <div className={'d-flex flex-column'}>
                                        {message.pic && <ImageComponent source={message.pic} alt="attachment" className="message-image" />}
                                        {message.audio && <AudioPlayer  controls src={message.audio} className="message-audio" extra={extra} />}
                                        {message.msg && <div className="wishtok_whatsapp_message-text">{message.msg}</div>}
                                        <div className="wishtok_whatsapp_message-time">{moment(message.at * 1000).format('hh:mm A')}</div>
                                    </div>
                                </div>
                            </div>
                        ))}
                </div>
            ))}
        </div>
    );
};

const ShareChat = () => {
    const { id, extra } = useParams();
    const [messages, setMessages] = useState([]);
    const { showError } = useContext(DialogContext);
    const customer=useRef(null);

    useEffect(() => {
        localStorage.setItem('token',extra)
    }, [id, extra])
    
    
    const fetchMessages = async () => {
        try {
            let res = await doGET(ENDPOINTS.grid('message', `conversation_id=${id}&sortAsc=false&rows=-1`));
            setMessages(res?.data?.data?.rows || []);
            customer.current=res?.data?.data?.customer_id
        } catch (error) {
            console.log(error);
            setMessages([]);
            showError("Failed to load messages");
        }
        finally{
        }
    };

    useEffect(() => {
        fetchMessages();
    }, [id]);

    return (
        <div className="wishtok_whatsapp_chat-window-container">
            <div className="wishtok_whatsapp_chat-window">
                <div className="wishtok_whatsapp_chat-header">
                    <div className="wishtok_whatsapp_chat-title"><img src={logo} height={20} width={130}/></div>
                </div>
                <MessageList messages={messages} customer={customer.current} extra={extra} />
            </div>
        </div>
    );
};

export default observer(ShareChat);

