import React, { useEffect, useState } from "react";
import ConsultantLabelService from "./ConsultantLabelService";
import { ThreeBounce } from "better-react-spinkit";
import image from "../../../assets/images/imagplaceholder.png";
import ImageComponent from "../../Image/ImageComponent";

const imageStyle = {
    height: "35px",
    width: "35px",
    borderRadius: "30px"
}

const ConsultantLabel = ({ consultant_id = "", showImage = false }) => {
    const [name, setName] = useState({ name: "", profileImage: "" });
    const [loading, setLoading] = useState(false);

    useEffect(() => {
        const fetchData = async () => {
            if (!consultant_id) return;
            try {
                setLoading(true);
                const response = await ConsultantLabelService.fetch(consultant_id);
                setName(response);
            } catch (error) {
                console.log(error);
                setName("--")
            } finally {
                setLoading(false);
            }
        };

        fetchData();
    }, [consultant_id]);

    if(!consultant_id) return "--"

    return <div>
        {
            loading ?
                <ThreeBounce /> : (
                    <div className="d-flex align-items-center gap-2">
                        {showImage ? <ImageComponent style={imageStyle} source={name?.profileImage ? `${name?.profileImage}` : image} /> : null}
                        <span>{name?.name}</span>
                    </div>
                )
        }
    </div>;
};

export default ConsultantLabel;