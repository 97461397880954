import React, { useEffect, useState, useContext } from "react";
import { Card, CardBody, Col, Row, Table, Input, Button } from "reactstrap";
import { DialogContext } from "../../store/context/DialogContext.js";
import { I18nContext } from "../../store/context/i18nContext.js";
import { Layout, SaveButton } from "../../components/index.js";
import CurrencyRateService from "./CurrencyRateService.js";
import { observer } from "mobx-react-lite";
import { FaMinus, FaPlus } from "react-icons/fa";

const CurrencyRate = () => {
    const { t } = useContext(I18nContext);
    const { showError, showAlert } = useContext(DialogContext);
    const [loading, setLoading] = useState(false);
    const [data, setData] = useState(null);
    const [markupValues, setMarkupValues] = useState({});
    const [searchTerm, setSearchTerm] = useState(""); 

    const fetchData = async () => {
        if (loading) return;
        setLoading(true);
        try {
            const response = await CurrencyRateService.getAllMarkup();
            setData(response?.data);

            const initialMarkup = {};
            response?.data?.rates &&
                Object.entries(response.data.rates).forEach(([currency, details]) => {
                    initialMarkup[currency] = details.markUp || 0;
                });
            setMarkupValues(initialMarkup);
        } catch (e) {
            showError(e);
        }
        setLoading(false);
    };

    useEffect(() => {
        fetchData();
    }, []);

    const handleMarkupChange = (currency, value) => {
        setMarkupValues((prev) => ({
            ...prev,
            [currency]: value,
        }));
    };

    const togglePositiveNegative = (currency, isPositive) => {
        let absValue = Math.abs(markupValues[currency] || 20); 
        setMarkupValues((prev) => ({
            ...prev,
            [currency]: isPositive ? absValue : -absValue,
        }));
    };

    const saveMarkupChanges = async () => {
        if (!data?.rates) return;
    
        const updatedPayload = Object.entries(data.rates).reduce((acc, [currency, details]) => {
            acc[currency] = {
                value: details.value,
                markUp: markupValues[currency] ?? 0,
            };
            return acc;
        }, {});
    
        try {
            let response =await CurrencyRateService.updateMarkup(updatedPayload);
            if(response?.status==200){
                showAlert({
                    title: "Currency Markup",
                    description:
                        "Markup has been updated Successfully",
                });
            }
          
        } catch (e) {
            showError(e);
        }
        finally{
            fetchData();
        }
    };
    

    const filteredCurrencies = data?.rates
        ? Object.entries(data.rates).filter(([currency]) =>
              currency.toLowerCase().includes(searchTerm.toLowerCase())
          )
        : [];

    return (
        <Card className="mt-4 shadow-sm">
            <CardBody>
                <Row className="mb-3">
                    <Col>
                        <h4 className="text-primary">{t("Currency Exchange Rates")}</h4>
                    </Col>
                    <Col className="d-flex justify-content-end">
                        <Input
                            type="text"
                            placeholder="🔍 Search currency..."
                            value={searchTerm}
                            onChange={(e) => setSearchTerm(e.target.value)}
                            className="form-control w-50"
                        />
                    </Col>
                </Row>
                <div className="table-responsive" style={{ maxHeight: "75vh", overflowY:"scroll" }}>
                    <Table bordered striped hover className="text-center align-middle">
                        <thead className="table-dark">
                            <tr>
                                <th>Currency</th>
                                <th>Value</th>
                                <th>Markup</th>
                            </tr>
                        </thead>
                        <tbody>
                            {filteredCurrencies.length > 0 ? (
                                filteredCurrencies.map(([currency, details]) => (
                                    <tr key={currency}>
                                        <td className="fw-bold">{currency}</td>
                                        <td>{details?.value}</td>
                                        <td>
                                            <div className="d-flex justify-content-center align-items-center">
                                                <Button
                                                    color={markupValues[currency] < 0 ? "danger" : "outline-danger"}
                                                    size="sm"
                                                    onClick={() => togglePositiveNegative(currency, false)}
                                                    className="rounded-circle me-2"
                                                    style={{ width: "36px", height: "36px" }}
                                                >
                                                    <FaMinus />
                                                </Button>
                                                <Input
                                                    type="number"
                                                    value={markupValues[currency]}
                                                    onChange={(e) =>
                                                        handleMarkupChange(currency, parseFloat(e.target.value) || 0)
                                                    }
                                                    className="form-control text-center"
                                                    style={{ width: "100px", borderRadius: "8px" }}
                                                />
                                                <Button
                                                    color={markupValues[currency] > 0 ? "success" : "outline-success"}
                                                    size="sm"
                                                    onClick={() => togglePositiveNegative(currency, true)}
                                                    className="rounded-circle ms-2"
                                                    style={{ width: "36px", height: "36px" }}
                                                >

                                                    <FaPlus />
                                                </Button>
                                            </div>
                                        </td>
                                    </tr>
                                ))
                            ) : (
                                <tr>
                                    <td colSpan="3" className="text-center text-muted">
                                        No currencies found
                                    </td>
                                </tr>
                            )}
                        </tbody>
                    </Table>
                </div>
                <Row className="mt-3">
                    <Col className="d-flex justify-content-end">
                        <SaveButton onClick={saveMarkupChanges} label={"Save Changes"} />
                    </Col>
                </Row>
            </CardBody>
        </Card>
    );
};

export default observer(CurrencyRate);
