import { makeAutoObservable, runInAction } from "mobx";
import { doGET } from "../../../util/httpUtil";

class Service {
    customerMap = {};
    pendingRequests = {};

    constructor() {
        makeAutoObservable(this);
    }

    fetch = async (customer_id) => {
        if (this.customerMap?.[customer_id]) {
            return this.customerMap?.[customer_id];
        }
        if (this.pendingRequests?.[customer_id]) {
            return this.pendingRequests?.[customer_id];
        }
        this.pendingRequests[customer_id] = (async () => {
            try {
                const response = await doGET(`/v1/api/user/grid?_id=${customer_id}`);
                const name =
                    response.status === 200 && response?.data?.data?.rows?.length > 0
                        ? `${response?.data?.data?.rows[0]?.fname ?? ""} ${response?.data?.data?.rows[0]?.lname ?? ""}`
                        : "--";

                runInAction(() => {
                    this.customerMap[customer_id] = { name, country: response?.data?.data?.rows[0]?.country };
                });

                return this.customerMap?.[customer_id];
            } catch (error) {
                console.error(error);
                return "--";
            } finally {
                delete this.pendingRequests?.[customer_id];
            }
        })();

        return this.pendingRequests?.[customer_id];
    };
}

const CustomerLabelService = new Service();
export default CustomerLabelService;