import React, { useState, useEffect, useContext } from "react";
import { Table, Input, Checkbox } from "antd";
import { ENDPOINTS } from "../../services/api/constants";
import { doGET, doPOST, doPUT } from "../../util/httpUtil";
import ConsultantService from "./ConsultantService";
import { DialogContext } from "../../store/context/DialogContext";
import { Button } from "reactstrap";
import { ThreeBounce } from "better-react-spinkit";
import { observer } from "mobx-react-lite";

const MindfulPlansTable = ({ consultantId }) => {
  const [dataList, setDataList] = useState([]);
  const { showError,showMessage } = useContext(DialogContext);
  const [loading, setLoading] = useState(false);

  const fetchAndMergePlans = async () => {
    try {
      setLoading(true)
      const res = await doGET(
        ENDPOINTS.grid("consultation-plan", `consultantId=${consultantId}`)
      );
      const fetchedPlans = res?.data?.data?.rows || [];

      const mergedPlans = ConsultantService.MindfulPlans.map((templatePlan) => {
        const existingPlan = fetchedPlans.find(
          (plan) => plan.type === templatePlan.type
        );
        const mergedPlan = existingPlan
          ? { ...templatePlan, ...existingPlan }
          : { ...templatePlan };

        Object.keys(templatePlan).forEach((key) => {
          if (!mergedPlan.hasOwnProperty(key)) {
            mergedPlan[key] = templatePlan[key];
          }
        });

        return {
          ...mergedPlan,
          id: existingPlan?._id,
          enabled: existingPlan?.isActive || false,
        };
      });

      setDataList(mergedPlans);
    } catch (error) {
      console.error("Failed to fetch consultation plans:", error);
    }
    finally{
      setLoading(false)
    }
  };

  const updateDataList = (index, field, value) => {
    const updatedList = [...dataList];
    if (typeof updatedList[index][field] === "object") {
      updatedList[index][field] = {
        ...updatedList[index][field],
        amount: value, 
      };
    } else {
      updatedList[index][field] = value;
    }
  
    setDataList(updatedList);
  };
  

  const handleSave = async (index) => {
    if(loading) return;
    setLoading(true);
    const plan = dataList[index];
    try {
      const payload = {
        ...plan,
        isActive: plan.enabled, 
      };

      if (plan.id) {
        // Update existing plan
        await doPUT(ENDPOINTS.update("consultation-plan"), {
          _id: plan.id,
          ...payload,
        });
      showMessage("success","Plan updated successfully!")
      } else {
        const res = await doPOST(ENDPOINTS.create("consultation-plan"), {
          ...payload,
          consultantId,
        });
        showMessage("success","Plan created successfully!");

        const newPlanId = res?.data?._id;
        if (newPlanId) {
          const updatedList = [...dataList];
          updatedList[index].id = newPlanId;
          setDataList(updatedList);
        }
      }

      fetchAndMergePlans();
    } catch (error) {
      showError("Failed to save consultation plan:", error)
    }
    finally{
      setLoading(false);
    }
  };

  useEffect(() => {
    fetchAndMergePlans();
  }, []);

  const columns = [
    {
      title: "Active",
      dataIndex: "active",
      key: "active",
      render: (_, record, index) => (
        <Checkbox
          checked={record.enabled}
          onChange={(e) =>
            updateDataList(index, "enabled", e.target.checked)
          }
        />
      ),
    },
    {
      title: "Plan Name",
      dataIndex: "name",
      key: "name",
      render: (_, record) => (
        <span>
          {record.name}{" "}
          {record.frequency === "Week"
            ? `(${record.duration.totalDuration} Weeks)`
            : `(${record.duration.totalDuration} Min)`}
        </span>
      ),
    },
    {
      title: "Rate",
      dataIndex: "price",
      key: "price",
      render: (_, record, index) => (
        <Input
          placeholder="Enter price"
          value={record.price?.amount}
          onChange={(e) => updateDataList(index, "price", e.target.value)}
        />
      ),
    },
    {
      title: "Discount Price",
      dataIndex: "discount",
      key: "discount",
      render: (_, record, index) => (
        <Input
          placeholder="Enter Discount Amount"
          value={record?.discount?.amount}
          onChange={(e) =>
            updateDataList(index, "discount", e.target.value)
          }
        />
      ),
    },
    {
      title: "Consultant Price",
      dataIndex: "consultantPrice",
      key: "consultantPrice",
      render: (_, record, index) => (
        <Input
          placeholder="Enter Consultant price"
          value={record?.consultantPrice?.amount}
          onChange={(e) =>
            updateDataList(index,"consultantPrice", e.target.value)
          }
        />
      ),
    },
    {
      title: "Description",
      dataIndex: "description",
      key: "description",
      render: (_, record, index) => (
        <Input
          placeholder="Enter description"
          value={record.description}
          onChange={(e) =>
            updateDataList(index, "description", e.target.value)
          }
        />
      ),
    },
    {
      title: "Actions",
      dataIndex: "actions",
      key: "actions",
      render: (_, record, index) => (
        <Button color="success" onClick={() => handleSave(index)}>
          {loading ? <ThreeBounce color={"#fff"}/> : "Save"}
        </Button>
      ),
    },
  ];

  return (
    <Table
      dataSource={dataList}
      columns={columns}
      rowKey={(record) => record.id || record.type}
      pagination={false}
    />
  );
};

export default observer(MindfulPlansTable);
