import { observer } from "mobx-react-lite";
import React, { useContext, useEffect, useState } from "react";
import { useLocation, useParams } from "react-router-dom";
import { Tbody, Td, Th, Thead, Tr } from "react-super-responsive-table";
import { Button, DropdownMenu, DropdownToggle, UncontrolledDropdown } from "reactstrap";
import {
    Layout, SaveButton,
} from "../../components";
import Payout from "./Payout";

import { dateFormat } from "../../util/helper";

import { DialogContext } from "../../store/context/DialogContext";
import { I18nContext } from "../../store/context/i18nContext";

import ConsultantService from "./ConsultantService";
import './style.css'
import ConsultantDetails from "./ConsultantDetails";


const ConsultantPayment = () => {
    const { id } = useParams()
    const { t } = useContext(I18nContext);

    const { showError, } = useContext(DialogContext);
    const [data, setData] = useState([]);
    const [loading, setLoading] = useState(false)
    const [showPayout, setShowPayout] = useState(-1)
    const [isPayoutArray, setIsPayoutArray] = useState([])
    const [fromDate, setFromDate] = useState();
    const [formattedFromDate, setFormattedFromDate] = useState();
    const [formattedToDate, setFormattedToDate] = useState();
    const [toDate, setToDate] = useState();
    const [forDownload, setForDownload] = useState(false);

    const [payoutData, setPayoutData] = useState({})

    const location = useLocation()
    const details = useState(location?.state?.data)


    // const fetchData = async () => {
    //     try {
    //         if (loading) return;
    //         setLoading(true);
    //         const response = await ConsultantService.payment(id)
    //         setData(response)
    //     } catch (e) {
    //         showError(e);
    //     }
    //     setLoading(false);
    // };

    const isPayout = async (t_id) => {
        try {
            if (loading) return;
            setLoading(true);

            const payout_ids_promise = data?.map(async (item) => ConsultantService?.isPayout({ transaction_id: item?._id }))
            const resposne = await Promise.all(payout_ids_promise);

            setIsPayoutArray(resposne)
        } catch (error) {
            showError(error);
        }
        setLoading(false);
    }


    useEffect(() => {
        if (data?.length) {
            isPayout();
        }

    }, [data])

    const closePayout = () => {
        setShowPayout(-1);
        setPayoutData(null)
    }

    // useEffect(() => {
    //     fetchData();
    // }, [ConsultantService?.paymentRows, ConsultantService?.paymentPage]);

    useEffect(() => {
        ConsultantService.resetPaymentData()
    }, [id])
    const formatDate = (inputDate = 0) => {
        const dateString = inputDate.toString();
        const year = dateString.substring(0, 4);
        const month = dateString.substring(4, 6);
        const day = dateString.substring(6, 8);
        const formattedDate = `${year}-${month}-${day}`;
        return formattedDate;
    };

    const fetchData = async ({ isDownload = false }) => {
        if (loading) return;
        if (!isDownload)
            setLoading(true);
        try {
            let filter = {};
            if (fromDate || toDate) {
                filter.fromDate = formattedFromDate;
                filter.tillDate = formattedToDate;
            }
            if (isDownload) {
                filter.forDownload = true;
            }
            const response = await ConsultantService.payment(id, filter);
            console.log(response);
            if (isDownload) {
                const csvContent = 'data:text/csv;charset=utf-8,' + encodeURIComponent(response?.data?.rows);
                const link = document.createElement('a');
                link.href = csvContent;
                link.target = '_blank';
                link.download = 'consultant_payment.csv';

                document.body.appendChild(link);
                link.click();
                document.body.removeChild(link);
            } else {
                setData(response)
            }
        } catch (e) {
            console.log(e, "================================================");

            showError(e);
        }
        setLoading(false);
    };

    useEffect(() => {
        fetchData({ isDownload: false });
    }, [ConsultantService?.paymentRows, ConsultantService?.paymentPage]);
    useEffect(() => {
        setFormattedFromDate(formatDate(fromDate));
        setFormattedToDate(formatDate(toDate));

    }, [fromDate, toDate]);
    console.log(data, "data")

    const changePagination=()=>{
        if(ConsultantService.paymentPage!=1){
            ConsultantService.paymentPage=1;
        }
      }
    return (
        <>
            <Layout
            changePagination={changePagination}
                setToDate={setToDate}
                setFromDate={setFromDate}
                showFromToDateFilter
                fromToPlaceholder={["From", "To"]}
                // filterInput={true}
                layoutForm={true}
                filterInput={true}
                hideNameInput={true}
                fetchData= {()=>fetchData({ isDownload: false })}
                download={true}
                forDownload={forDownload}
                setForDownload={setForDownload}
                gridLoading={loading}
                showViewPage={true}
                style={{ height: "83vh", overFlowY:"hidden"}}
                viewSection={<ConsultantDetails data={details} />}
                rows={ConsultantService.paymentRows}
                total={ConsultantService.paymentTotalRecords}
                page={ConsultantService.paymentPage}
                onPageChange={async (paymentPage, paymentRows) => {
                    await ConsultantService.onPaymentPaginationChange(paymentPage, paymentRows);
                }}
                title={t("Payment Records")}

            >

                <Layout.TheadFull>
                    <Thead className="thead-dark">
                        <Tr >
                            <Th>{t("Invoice No")}</Th>
                            <Th>{t("Date")}</Th>
                            <Th>{t("Status")}</Th>
                            <Th>{t("Order Type")}</Th>
                            <Th className="text-center">{t("Amount")}</Th>
                            <Th className="text-center">{t("Message")}</Th>
                            <Th className="text-center">{t("Offer")}</Th>
                            <Th className="text-center">{t("Action")}</Th>
                        </Tr>
                    </Thead>
                </Layout.TheadFull>

                <Layout.TbodyFull>
                    <Tbody>
                        {data?.map((payment, i) => (
                            <Tr
                                key={i} >
                                <Td>
                                    {payment?.txn_no} <br />
                                </Td>
                                <Td style={{ "minHeight": "30px" }}>
                                    {payment?.updatedAt ? dateFormat(payment?.updatedAt, "DD-MM-YYYY HH:MM") : ""}
                                </Td>
                                <Td>
                                    {payment?.status == 1 ? "Initiated" : ""}
                                    {payment?.status == 2 ? "Successful" : ""}
                                    {payment?.status == 3 ? "Failed" : ""}
                                    {payment?.status == 10 ? "Manual" : ""}
                                </Td>
                                <Td>
                                    {payment?.type == 1 ? "Chat" : ""}
                                    {payment?.type == 2 ? "Voice Call" : ""}
                                    {payment?.type == 3 ? "Video Call" : ""}
                                </Td>
                                <Td className="text-center">
                                    {payment?.amount}
                                </Td>
                                <Td className="text-center">
                                    {payment?.msg}
                                </Td>
                                <Td className="text-center">
                                    {payment?.offerCode}
                                </Td>

                                <Td className="float-right" >

                                    {isPayoutArray?.[i]?.data ?
                                        <div style={{ "color": "green" }} className="d-flex justify-content-end p-4 p-0 pt-2 pb-4 col">
                                            Payout Done {"   "} <i className="fa fa-check ms-2" style={{
                                            }} aria-hidden="true"></i>
                                        </div>
                                        : <SaveButton
                                            className="p-0 pt-2 pb-4"
                                            onClick={() => {
                                                setShowPayout(i)
                                                setPayoutData(payment)
                                            }}
                                            label={"Payout"}
                                            disabled={data ? false : true}
                                        />}

                                </Td>
                            </Tr>
                        ))}
                    </Tbody>
                </Layout.TbodyFull>
            </Layout>
            {showPayout !== -1 && <Payout setClose={closePayout} payoutData={payoutData} setIsPayoutArray={setIsPayoutArray} rowClicked={showPayout} />}
        </>
    );
};

export default observer(ConsultantPayment);