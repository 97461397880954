export const ENDPOINTS = {
    grid: (query = '') => `/api/auth/get/config${query}`,
    edit: () => `/api/auth/update/config`
};

export const PAYMENT_GATEWAY= [
    {label:"TAP", value:"TAP"},
    {label:"TELLR", value:"TELLR"},

]


