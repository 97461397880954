export const ENDPOINTS = {
    save: `/api/v1/payments/currency/save`,
    grid: (query = '') => `/api/v1/payments/currency/grid${query}`,
    getExchangeRate: (to) => `/api/v1/payments/currency/get?to=${to}`,
    getAllMarkup: `/v1/api/exchange-rate/get`,
    saveMarkup:`/v1/api/exchange-rate/save`
};



