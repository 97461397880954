export const ENDPOINTS = {
  grid: (page, rowPerPage, filter) => {
    let url = `/api/v1/payments/get/grid?type=1&rowPerPage=` + rowPerPage + "&page=" + page
    if (filter?.name) {
      url += `&name=${filter?.name}`
    }
    if (filter?.fromDate) {
      url += `&fromDate=${filter?.fromDate}`
    }
    if (filter?.tillDate) {
      url += `&tillDate=${filter?.tillDate}`
    }
    if (filter?.forDownload) {
      url += `&forDownload=${filter?.forDownload}`
    }
    if (filter?.userType) {
      url += `&userType=${filter?.userType}`
    }
    if (filter?.searchStatus) {
      url += `&status=${filter?.searchStatus}`
    }
    return url
  },

  getUser: (id) => {
    let url = `/api/profile/get/customer/${id}`
    return url
  },
};

export const SELECTSTRUCTURE = {
  // shiftName: [
  //   { label: "10-4", value: 1 },
  //   { label: "Male", value: 2 },
  //   { label: "Others", value: 3 },

  // ],
  gender: [
    { label: "Mrs", value: 0 },
    { label: "Mr", value: 1 },
    { label: "Miss", value: 2 },
    { label: "Others", value: 3 },
  ],
};

export const PAYMENT_RESPONSE = {
  "000": { message: "Captured" },
  "001": { message: "Authorized" },
  "100": { message: "Initiated" },
  "200": { message: "In Progress" },
  "301": { message: "Abandoned" },
  "302": { message: "Canceled" },
  "303": { message: "Deferred" },
  "304": { message: "Expired" },
  "401": { message: "Failed" },
  "402": { message: "Failed, Invalid Parameter" },
  "403": { message: "Failed, Duplicate" },
  "404": { message: "Failed, Locked" },
  "405": { message: "Failed, Invalid Card No" },
  "406": { message: "Failed, Invalid Expiry" },
  "407": { message: "Failed, Expired Card" },
  "408": { message: "Failed, Unspecified Failure" },
  "501": { message: "Declined" },
  "502": { message: "Declined, Incorrect CSC/CVV" },
  "503": { message: "Declined, 3D Security - Incorrect" },
  "504": { message: "Declined, 3D Security - Card not Enrolled" },
  "505": { message: "Declined, Insufficient Funds" },
  "506": { message: "Declined, Transaction Type Not Supported" },
  "507": { message: "Declined, Card Issuer" },
  "508": { message: "Declined, Card Issuer - No Reply" },
  "509": { message: "Declined, Card Issuer - Do not Contact" },
  "510": { message: "Declined, Card Issuer - Referral Response" },
  "511": { message: "Declined, Card Issuer - Error" },
  "512": { message: "Declined, Not Authenticated" },
  "513": { message: "Declined, Card Acquirer - Error" },
  "514": { message: "Declined, Card Issuer - Risk Check" },
  "515": { message: "Declined, Tap" },
  "516": { message: "Declined, Authentication Failed" },
  "601": { message: "Void" },
  "701": { message: "Restricted" },
  "702": { message: "Restricted, Retry Limit Exceeded" },
  "703": { message: "Restricted, Bank" },
  "704": { message: "Restricted, Tap" },
  "801": { message: "Timed Out" },
  "901": { message: "Unknown" },
};

export const STRUCTURE = [
  {
    label: "Client",
    description: "description...",
    filterLabel: "Client",
    filterName: "corp_id",
    type: "clientSelect",
  },
  {
    label: "Business Unit",
    description: "description...",
    filterLabel: "Business Unit",
    filterName: "bu_id",
    type: "entitySelect",
    dependency: {
      client: 'corp_id'
    }
  },
  {
    label: "Work Shift",
    description: "description...",
    filterLabel: "Work Shift",
    filterName: "dutyhrs_id",
    type: "workshiftSelect",
  },
  {
    label: "Campus",
    description: "description...",
    filterLabel: "Campus",
    filterName: "campus_id",
    type: "campusSelect",
  },
  {
    label: "Rank",
    description: "description...",
    filterLabel: "Rank",
    filterName: "rank",
    type: "rankSelect",
  },
  {
    label: "Designation",
    description: "description...",
    filterLabel: "Designation",
    filterName: "designation",
    type: "designationSelect",
  },
  {
    label: "Name",
    description: "description...",
    filterLabel: "Name",
    filterName: "name",
    type: "text",
  },
  {
    label: "Email",
    description: "description...",
    filterLabel: "Email",
    filterName: "email",
    type: "text",
  },
  {
    label: "From Date",
    description: "description...",
    filterLabel: "From Date",
    filterName: "joinFrom",
    type: "dateSelect",
    getTimestamp: true
  },
  {
    label: "Till Date",
    description: "description...",
    filterLabel: "Till Date",
    filterName: "joinTill",
    type: "dateSelect",
    getTimestamp: true
  },
  {
    label: "Time Zone",
    description: "description...",
    filterLabel: "Time Zone",
    filterName: "timezone",
    type: "timezoneSelect",
  },
  {
    label: "Country Code",
    description: "description...",
    filterLabel: "Country Code",
    filterName: "countryCode",
    type: "countrySelect",
    isd: true,
  },
  {
    label: "Phone",
    description: "description...",
    filterLabel: "Phone",
    filterName: "phone",
    type: "phone",
  },
  {
    label: "Country",
    description: "description...",
    filterLabel: "Country",
    filterName: "residence.country",
    type: "countrySelect",
  },
  {
    label: "City",
    description: "description...",
    filterLabel: "City",
    filterName: "residence.city",
    type: "citySelect",
    dependency: {
      country: 'residence.country',
    },
    onlyCountry: true,
  },
  {
    label: "Is On App",
    description: "description...",
    filterLabel: "Is On App",
    filterName: "onApp",
    type: "checkBox",
  },
  {
    label: "ETS Enabled",
    description: "description...",
    filterLabel: "ETS Enabled",
    filterName: "ets",
    type: "checkBox",
  },
  {
    label: "Spot Enabled",
    description: "description...",
    filterLabel: "Spot Enabled",
    filterName: "etsSpot",
    type: "checkBox",
  },
  {
    label: "Tracking Enabled",
    description: "description...",
    filterLabel: "Traking Enabled",
    filterName: "tracking",
    type: "checkBox",
  },
  {
    label: "Location coordinate not near from address",
    description: "description...",
    filterLabel: "Location coordinate not near from address",
    filterName: "notNear",
    type: "checkBox",
  },
  {
    label: "Location not populated properly",
    description: "description...",
    filterLabel: "Location not populated properly",
    filterName: "showLocAbsent",
    type: "checkBox",
  },
  {
    label: "Within Hotspot",
    description: "description...",
    filterLabel: "Within Hotspot",
    filterName: "withinHotspot",
    type: "checkBox",
  },
];
