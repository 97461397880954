import { makeAutoObservable, runInAction } from "mobx";
import { doGET } from "../../../util/httpUtil";

class Service {
    consultantMap = {};
    pendingRequests = {};

    constructor() {
        makeAutoObservable(this);
    }

    fetch = async (consultant_id) => {
        if (this.consultantMap?.[consultant_id]) {
            return this.consultantMap?.[consultant_id];
        }
        if (this.pendingRequests?.[consultant_id]) {
            return this.pendingRequests?.[consultant_id];
        }
        this.pendingRequests[consultant_id] = (async () => {
            try {
                const response = await doGET(`/v1/api/consultant/grid?_id=${consultant_id}`);
                const displayName =
                    response.status === 200 && response?.data?.data?.rows?.length > 0
                        ? response?.data?.data?.rows[0]?.displayName
                        : "--";

                runInAction(() => {
                    this.consultantMap[consultant_id] = { name: displayName, profileImage: response?.data?.data?.rows[0]?.profileImage };
                });

                return this.consultantMap?.[consultant_id];
            } catch (error) {
                console.error(error);
                return "--";
            } finally {
                delete this.pendingRequests?.[consultant_id];
            }
        })();

        return this.pendingRequests?.[consultant_id];
    };
}

const ConsultantLabelService = new Service();
export default ConsultantLabelService;