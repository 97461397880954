import React, { useEffect, useState } from "react";
import CustomerLabelService from "./CustomerLabelService";
import { ThreeBounce } from "better-react-spinkit";

const CustomerLabel = ({ customer_id = "", showCountry = false }) => {
    const [name, setName] = useState({ name: "", country: "" });
    const [loading, setLoading] = useState(false);

    useEffect(() => {
        const fetchData = async () => {
            if (!customer_id) return;
            try {
                setLoading(true);
                const response = await CustomerLabelService.fetch(customer_id);
                setName(response);
            } catch (error) {
                console.log(error);
                setName("--")
            } finally {
                setLoading(false);
            }
        };

        fetchData();
    }, [customer_id]);

    return <div>
        {loading ?
            <ThreeBounce /> :
            <div className="d-flex align-items-center gap-2">
                <div>{name?.name}</div>
                {showCountry ? <div>{name?.country}</div> : null}
            </div>
        }
    </div>;
};

export default CustomerLabel;