import React, { useContext, useEffect, useState } from 'react'
import { I18nContext } from '../../store/context/i18nContext';
import { DialogContext } from '../../store/context/DialogContext';
import moment from 'moment';
import FreeChatService from './FreeChatService';
import { Layout } from '../../components';
import { Tbody, Td, Th, Thead, Tr } from 'react-super-responsive-table';
import ConsultantLabel from '../../components/Labels/Consultant/ConsultantLabel';
import CustomerLabel from '../../components/Labels/Customer/CustomerLabel';
import { observer } from 'mobx-react-lite';
import { endReasonMap, statusMap } from './FreeChatConstant';

const FreeChatAnalytics = () => {
    const { t } = useContext(I18nContext);
    const { showError } = useContext(DialogContext);
    const [loading, setLoading] = useState(false);
    const [fromDate, setFromDate] = useState(moment().subtract(1, 'months').format('YYYYMMDD'))
    const [tillDate, setTillDate] = useState(moment().format('YYYYMMDD'))

    function formatTimeDifference(timestamp1, timestamp2) {
        if (!timestamp1 || !timestamp2) return "--";
        let diffInSeconds = Math.abs(timestamp2 - timestamp1);
        let duration = moment.duration(diffInSeconds, 'seconds');
        let parts = [];
        if (duration.hours() > 0) parts.push(`${duration.hours()} hour${duration.hours() > 1 ? 's' : ''}`);
        if (duration.minutes() > 0) parts.push(`${duration.minutes()} minute${duration.minutes() > 1 ? 's' : ''}`);
        if (duration.seconds() > 0) parts.push(`${duration.seconds()} second${duration.seconds() > 1 ? 's' : ''}`);
        return parts.join(', ') || '0 seconds';
    }

    const fetchData = async () => {
        if (loading) return;
        try {
            setLoading(true);
            const filter = {}
            if (fromDate && tillDate) {
                filter.fromDate = moment(fromDate, 'YYYYMMDD').startOf('day').unix();
                filter.tillDate = moment(tillDate, 'YYYYMMDD').startOf('day').unix();
            }
            await FreeChatService.fetchAnalytics(filter);
        } catch (e) {
            showError(e);
        }
        finally {
            setLoading(false);
        }
    };

    useEffect(() => {
        fetchData();
    }, [FreeChatService.rowsAnalytics, FreeChatService.pageAnalytics, fromDate, tillDate]);

    const changePagination = () => {
        if (FreeChatService.pageAnalytics != 1) {
            FreeChatService.pageAnalytics = 1;
        }
    }

    return (
        <>
            <Layout
                title="Free Chats"
                changePagination={changePagination}
                fetchData={fetchData}
                gridLoading={loading}
                rows={FreeChatService.rowsAnalytics}
                total={FreeChatService.totalRecordsAnalytics}
                page={FreeChatService.pageAnalytics}
                toDate={tillDate}
                setFromDate={setFromDate}
                setToDate={setTillDate}
                showFromToDateFilter
                fromDate={fromDate}
                onPageChange={async (page, rows) => {
                    FreeChatService.onPaginationChangeAnalytics(page, rows);
                }}
            >
                <Layout.TheadFull>
                    <Thead className="thead-dark">
                        <Tr>
                            <Th>{t("Consultant")}</Th>
                            <Th>{t("User")}</Th>
                            <Th>{t("At")}</Th>
                            <Th>{t("Status")}</Th>
                            <Th>{t("Call End Reason")}</Th>
                            <Th className="text-end">{t("Duration")}</Th>
                        </Tr>
                    </Thead>
                </Layout.TheadFull>

                <Layout.TbodyFull>
                    {
                        FreeChatService.recordsAnalytics?.length == 0 ? <>
                            <div className='fs-6 fw-bold mt-4'>No Records Found</div>
                        </> : <>
                            <Tbody style={{ maxHeight: "100px", overflowY: "scroll" }}>
                                {FreeChatService.recordsAnalytics?.map((chat) => (
                                    <Tr key={chat?._id}>
                                        <Td><ConsultantLabel showImage consultant_id={chat?.consultant_id} /></Td>
                                        <Td><CustomerLabel showCountry customer_id={chat?.customer_id} /></Td>
                                        <Td>{moment.unix(chat?.requestedAt).format("DD-MM-YY HH:mm")}</Td>
                                        <Td>{statusMap[chat?.status]}</Td>
                                        <Td>{endReasonMap[chat?.endReason] ?? "--"}</Td>
                                        <Td>{formatTimeDifference(chat?.stopAt, chat?.startAt)}</Td>
                                    </Tr>
                                ))}
                            </Tbody>
                        </>
                    }
                </Layout.TbodyFull>
            </Layout>
        </>
    );
}

export default observer(FreeChatAnalytics);